import * as actionTypes from "../constants";
import { updateObject } from "../../utils";

const initialState = {
  identificationTypes: [],
  paymentLimits: {
    min: null,
    max: null,
  },
};

const setIdentificationTypes = (state, action) => {
  return updateObject(state, {
    identificationTypes: action.identificationTypes,
  });
};

const setPaymentLimits = (state, action) => {
  return updateObject(state, {
    paymentLimits: action.paymentLimits,
  });
};

const genericParams = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IDENTIFICATION_TYPES:
      return setIdentificationTypes(state, action);
    case actionTypes.FETCH_PAYMENT_LIMITS:
      return setPaymentLimits(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default genericParams;
