/* eslint-disable no-undef */
import axios from "axios";
import * as actionTypes from "../constants";
import { setLoading, setError } from "./user_action";

const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL;

export const pushIdentificationTypes = (identificationTypes) => {
  return {
    type: actionTypes.FETCH_IDENTIFICATION_TYPES,
    identificationTypes: identificationTypes,
  };
};

const setPaymentLimits = (paymentLimits) => {
  return {
    type: actionTypes.FETCH_PAYMENT_LIMITS,
    paymentLimits: paymentLimits,
  };
};

export const fetchIdentificationTypes = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    const userToken = sessionStorage.getItem("token");
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const fetchIdentificationTypesUrl = `${BACKEND_API}/generic-params/document-types/index`;
    axios
      .get(fetchIdentificationTypesUrl, authRequestConfig)
      .then((response) => {
        const identificationTypes = response.data.data.document_types;
        console.log("document types", identificationTypes);
        dispatch(pushIdentificationTypes(identificationTypes));
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const fetchPaymentLimits = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    const userToken = sessionStorage.getItem("token");
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const fetchPaymentLimits = `${BACKEND_API}/generic-params/payment-limits/index`;
    axios
      .get(fetchPaymentLimits, authRequestConfig)
      .then((response) => {
        const paymentLimits = response.data.data.payment_limits;
        console.log("paymentLimits", paymentLimits);
        dispatch(setPaymentLimits(paymentLimits));
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
