import * as actionTypes from "../constants";
import { updateObject } from "../../utils";

const initialState = {
  policies: null,
  pagination: {
    activePage: 1,
    perPage: 5,
    totalItems: 0,
    pageRangeDisplayed: 5,
  },
  invoiceDownload: {
    filename: null,
    temporaryUrl: null,
  },
  checkedPolicies: [],
};

const setPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const fetchPaidPolicies = (state, action) => {
  return updateObject(state, {
    policies: action.policies,
  });
};

const downloadPaidPolicies = (state, action) => {
  return updateObject(state, {
    invoiceDownload: action.invoicesDownloadData,
  });
};

const checkAllPaidPolicies = (state, action) => {
  return updateObject(state, {
    checkedPolicies: action.checkedPolicies,
  });
};

const paid = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICIES_PAID_FETCH:
      return fetchPaidPolicies(state, action);
    case actionTypes.POLICIES_PAID_DOWNLOAD:
      return downloadPaidPolicies(state, action);
    case actionTypes.POLICIES_PAID_SET_PAGINATION:
      return setPagination(state, action);
    case actionTypes.POLICIES_PAID_CHECK_ALL:
      return checkAllPaidPolicies(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default paid;
