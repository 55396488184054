import * as actionTypes from "../constants";
import axios from "axios";
import { parsePendingPolicy, formatRequestReports } from "../../utils";
import { setLoading, setError } from "./user_action";
const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL;
const REPORT_BACKEND_API = process.env.REACT_APP_MICROSERVICE_BACKEND_API_URL;

const setPolicies = (policies, totalValuePortfolio = 0) => {
  return {
    type: actionTypes.POLICIES_PENDING_FETCH,
    policies,
    totalValuePortfolio,
  };
};

const setInsuranceCode = (insuranceCode) => {
  return {
    type: actionTypes.SET_INSURANCE_CODE,
    insuranceCode: insuranceCode,
  };
};

const setPagination = (pagination) => {
  return {
    type: actionTypes.POLICIES_PENDING_SET_PAGINATION,
    pagination,
  };
};

export const checkPendingPolicy = (_id, isChecked) => {
  return {
    type: actionTypes.POLICIES_PENDING_CHECK,
    policy: { _id, isChecked },
  };
};

export const checkAllPendingPolicies = (checkedPolicies, total) => {
  return {
    type: actionTypes.POLICIES_PENDING_CHECK_ALL,
    checkedPolicies: checkedPolicies,
    total: total,
  };
};

export const uncheckAllPendingPolicies = () => {
  return {
    type: actionTypes.POLICIES_PENDING_UNCHECK_ALL,
  };
};

export const verifyCheckPendingPolicies = () => {
  return {
    type: actionTypes.VERIFY_CHECK_PENDING_POLICIES,
  };
};

export const setPreviousPendingPolicies = () => {
  return {
    type: actionTypes.SET_PREVIOUS_PENDING_POLICIES,
  };
};

export const fetchPendingPolicies = (
  filters = {
    productType: "",
    order: "FECHA-ASC",
    dateRange: { start: "", end: "" },
  },
  pagination = {
    activePage: 1,
    perPage: 5,
  },
  search = "",
  checkAll = false
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    let requestPayload = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
        },
        filters: filters
          ? {
              product_type: filters.productType || "",
              order_by: filters.order?.split("-")[0] || "",
              order_type: filters.order?.split("-")[1] || "",
              start_date: filters.dateRange?.start
                ? filters.dateRange.start.toISOString()
                : "",
              end_date: filters.dateRange?.end
                ? filters.dateRange.end.toISOString()
                : "",
              field_search: search || "",
            }
          : {},
        is_all_pages: checkAll ? true : false,
        pagination: {
          page_number: pagination.activePage,
          page_size: pagination.perPage,
        },
      },
    };

    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    console.log("format pending policies: ", requestPayload);
    axios
      .post(
        `${BACKEND_API}/policy/pending/index`,
        requestPayload,
        requestConfig
      )
      .then(({ data }) => {
        if (data) {
          console.log("response for pending policies: ", data);
          let policies = {};
          let total = 0;
          data.data.policies.map((policy, index) => {
            console.log(policy);
            const parsedPolicy = parsePendingPolicy(policy, index, checkAll);
            policies[parsedPolicy["index"]] = parsedPolicy;
            total += parseFloat(parsedPolicy.total);
          });
          if (!checkAll) {
            let totalValuePortfolio = data.data.info_porfolio.total_value;
            console.log("response for object policies: ", policies);
            dispatch(setPreviousPendingPolicies());
            dispatch(setPolicies(policies, totalValuePortfolio));
            dispatch(
              setPagination({
                ...pagination,
                totalItems: data.data.info_porfolio.total_records,
              })
            );
            // with all pending policies dispatch select policy
          } else {
            // dispatch checked policies
            let checkedPolicies = {};
            if (policies) {
              Object.values(policies).forEach((policy) => {
                checkedPolicies[policy._id] = policy;
              });
            }
            dispatch(checkAllPendingPolicies(checkedPolicies, total));
          }
          // set insurance code for payment
          dispatch(setInsuranceCode(data.data.client.insurance_code));
          // comparison
          dispatch(verifyCheckPendingPolicies());
        } else {
          dispatch(setPolicies({}));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setPolicies({}));
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadPendingPDF = (
  filters = {
    productType: "",
    order: "FECHA-ASC",
    dateRange: { start: "", end: "" },
  },
  pagination = {
    activePage: 1,
    perPage: 5,
  },
  search = ""
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const documentNumber = sessionStorage.getItem("document_number");
    const documentType = sessionStorage.getItem("document_type");
    let profileInfo = sessionStorage.getItem("profile_info");
    profileInfo = profileInfo ? JSON.parse(profileInfo) : null;
    const userName = profileInfo && profileInfo.name ? profileInfo.name : "";
    let requestPayload = formatRequestReports(
      documentNumber,
      documentType,
      userName,
      filters,
      pagination,
      search
    );
    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    console.log("format pending policies: ", requestPayload);
    axios
      .post(
        `${REPORT_BACKEND_API}/reports/pending/pdf`,
        requestPayload,
        requestConfig
      )
      .then(({ data }) => {
        console.log("fetch report response: ", data);
        window.open(data.data.file_url);
      })
      .catch((error) => {
        console.log("error generating xlsx report: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadPendingXLSX = (
  filters = {
    productType: "",
    order: "FECHA-ASC",
    dateRange: { start: "", end: "" },
  },
  pagination = {
    activePage: 1,
    perPage: 5,
  },
  search = ""
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const documentNumber = sessionStorage.getItem("document_number");
    const documentType = sessionStorage.getItem("document_type");
    let profileInfo = sessionStorage.getItem("profile_info");
    profileInfo = profileInfo ? JSON.parse(profileInfo) : null;
    const userName = profileInfo && profileInfo.name ? profileInfo.name : "";
    let requestPayload = formatRequestReports(
      documentNumber,
      documentType,
      userName,
      filters,
      pagination,
      search
    );
    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    console.log("format pending policies: ", requestPayload);
    axios
      .post(
        `${REPORT_BACKEND_API}/reports/pending/xlsx`,
        requestPayload,
        requestConfig
      )
      .then(({ data }) => {
        console.log("fetch report response: ", data);
        window.open(data.data.file_url);
      })
      .catch((error) => {
        console.log("error generating xlsx report: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
