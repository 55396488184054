import React from "react";
import Loader from "../../../../assets/images/loader.svg";
import LibertyArm from "../../../../assets/images/liberty-arm.svg";

import "./Loading.scss";

const Loading = () => {
  return (
    <div className="loading__container">
      <div className="loading d-flex justify-content-center align-self-center align-items-center">
        <img alt="" src={Loader} className="loading__spinner" />
        <img alt="" src={LibertyArm} className="loading__logo" />
        <h3 className="loading__legend">Procesando...</h3>
      </div>
    </div>
  );
};

export default Loading;
