import axios from "axios";
import { format, subHours } from "date-fns";
import * as actionTypes from "../constants";
import { setLoading, setError } from "./user_action";

// eslint-disable-next-line no-undef
const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL;
const MICROSERVICE_BACKEND_API =
  process.env.REACT_APP_MICROSERVICE_BACKEND_API_URL;

const setPaymentToken = (paymentToken) => {
  return {
    type: actionTypes.SET_PAYMENT_TOKEN,
    paymentToken,
  };
};

export const createCreditCardTransaction = (
  {
    email,
    lastName,
    name,
    phoneNumber,
    // secondLastName,
    binNumber,
    // address,
    isCurrentPayment,
  },
  insuranceCode,
  total,
  checkedPolicies
) => {
  return (dispatch) => {
    setPaymentToken(null);
    const userToken = sessionStorage.getItem("token");
    const requestBody = {
      data: {
        user_id: sessionStorage.getItem("document_number"),
        user_id_type: sessionStorage.getItem("document_type"),
        user_name: `${name} ${lastName}`,
        bin_number: binNumber,
        is_deferred: !isCurrentPayment, //TODO: Review
        policies: checkedPolicies,
        total,
        user_email: email,
        user_phone_number: phoneNumber,
        insurance_code: insuranceCode,
      },
    };
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const verifyUserUrl = `${BACKEND_API}/payment/credit-card/create`;
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(verifyUserUrl, requestBody, authRequestConfig)
      .then((response) => {
        console.log("response credit card token", response.data.data.token);
        dispatch(setPaymentToken(response.data.data.token));
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        //error.response.data.data.isBinError flag could be an alternative
        if (error.response && error.response.status === 406) {
          dispatch(
            setError({ isBinError: true, message: "Número Bin inválido" })
          );
        } else {
          dispatch(setError(true));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const setPaymentData = (paymentData) => {
  return {
    type: actionTypes.CHECK_PAYMENT_CONFIRMATION,
    paymentData,
  };
};

const parsePaymentDataCredit = (data) => {
  console.log("data on confirmation: ", data);
  const {
    type,
    succeed,
    company_name,
    terminal,
    transaction_number,
    transaction_date,
    total_value,
    authorization_number,
    descriptionResponse,
    operator,
    installments,
    card,
    card_type,
    bank,
    batch_number,
    reference,
    id_billing,
  } = data.payload;

  return {
    type,
    succeed,
    reference,
    company_name,
    terminal,
    transaction_number,
    transaction_date: transaction_date
      ? format(subHours(new Date(transaction_date), 5), "yyyy/MM/dd")
      : format(new Date(), "yyyy/MM/dd"),
    transaction_time: transaction_date
      ? format(subHours(new Date(transaction_date), 5), "HH:mm:ss")
      : format(new Date(), "HH:mm:ss"),
    total_value,
    authorization_number,
    descriptionResponse,
    operator,
    installments,
    card,
    card_type,
    bank,
    batch_number,
    idBilling: id_billing,
  };
};

export const checkPaymentConfirmation = (tokenId) => {
  return (dispatch) => {
    console.log(tokenId);
    const userToken = sessionStorage.getItem("token");
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    const paymentDataCreditUrl = `${BACKEND_API}/payment/credit-card/show-ticket/${tokenId}`;
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .get(paymentDataCreditUrl, authRequestConfig)
      .then((response) => {
        const paymentDataCredit = parsePaymentDataCredit(response.data.data);
        dispatch(setPaymentData(paymentDataCredit));
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const setPaymentDownloadData = (paymentDownloadData) => {
  return {
    type: actionTypes.DOWNLOAD_PAYMENT_CONFIRMATION,
    paymentDownloadData,
  };
};

export const downloadPaymentConfirmation = (paymentData) => {
  return (dispatch) => {
    const invoiceNumber = paymentData.idBilling;
    console.log(invoiceNumber);
    const userToken = sessionStorage.getItem("token");
    const requestBody = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
        },
        invoice: parseInt(invoiceNumber, 10), //TODO
      },
    };
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    console.log("REQUEST INVOICE");
    console.log(requestBody);

    const invoiceEndpoint = `${BACKEND_API}/policy/paid/invoice`;
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(invoiceEndpoint, requestBody, authRequestConfig)
      .then((response) => {
        console.log("invoice download response", response);
        dispatch(
          setPaymentDownloadData({
            //TODO: review
            filename: response.data.file_name,
            temporaryUrl: response.data.temporary_url,
          })
        );
        window.open(response.data.temporary_url);
      })
      .catch((error) => {
        setPaymentDownloadData(null);
        dispatch(setError(true));
        console.log("error requesting invoice: ", error);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const resetBillDownload = () => {
  return (dispatch) => {
    dispatch(
      setPaymentBill({
        temporaryUrl: null,
      })
    );
  };
};

const setPaymentBill = (paymentBillDownload) => {
  return {
    type: actionTypes.SET_PAYMENT_BILL,
    paymentBillDownload,
  };
};

export const downloadPaymentBill = (userProfile, checkedPolicies) => {
  return (dispatch) => {
    const name = userProfile.name ? userProfile.name : "";
    const userToken = sessionStorage.getItem("token");
    const requestBody = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
          user_name: `${name}`,
        },
        policies: checkedPolicies,
      },
    };
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    console.log("request payload: ", requestBody);
    // TODO put microservice endpoint
    const invoiceEndpoint = `${MICROSERVICE_BACKEND_API}/invoices/download`; // TODO: Change
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(invoiceEndpoint, requestBody, authRequestConfig)
      .then((response) => {
        console.log("invoice download response", response);
        window.open(response.data.data.file_url);
        dispatch(
          setPaymentBill({
            temporaryUrl: response.data.data.file_url,
          })
        );
      })
      .catch((error) => {
        console.log("error requesting invoice url: ", error);
        dispatch(setPaymentBill(null));
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const resetBillEmail = () => {
  return (dispatch) => {
    dispatch(setPaymentBillEmail(null));
  };
};

const setPaymentBillEmail = (paymentBillEmail) => {
  return {
    type: actionTypes.SET_PAYMENT_BILL_EMAIL,
    paymentBillEmail: paymentBillEmail,
  };
};

export const sendByEmailPaymentBill = (userProfile, email, checkedPolicies) => {
  return (dispatch) => {
    const name = userProfile.name ? userProfile.name : "";
    const userToken = sessionStorage.getItem("token");
    const requestBody = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
          user_name: `${name}`,
        },
        email: email,
        policies: checkedPolicies,
      },
    };
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };
    console.log("request payload: ", requestBody);
    // TODO put microservice endpoint
    const invoiceEndpoint = `${MICROSERVICE_BACKEND_API}/mailer/send/physical`; // TODO: Change
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(invoiceEndpoint, requestBody, authRequestConfig)
      .then((response) => {
        console.log("invoice download response", response);
        dispatch(setPaymentBillEmail(response));
      })
      .catch((error) => {
        console.log("error requesting email delivery: ", error);
        dispatch(setPaymentBill(null));
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
