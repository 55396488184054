import * as actionTypes from "../constants";
import { updateObject, updatePendingPolicies } from "../../utils";

const initialState = {
  insuranceCode: 0,
  total: 0,
  totalValuePortfolio: 0,
  totalItems: 0,
  policies: null,
  prevPolicies: null,
  checkedPolicies: null,
  pagination: {
    activePage: 1,
    perPage: 5,
    totalItems: 0,
    pageRangeDisplayed: 5,
  },
};

/* */
const fetchPendingPolicies = (state, action) => {
  let fetchedPolicies = action.policies;
  let totalValuePortfolio = action.totalValuePortfolio;
  /* if (Object.values(checkedPolicies).length > 0) {
    Object.values(checkedPolicies).forEach((checkedPolicy) => {
      if (fetchedPolicies[checkedPolicy.index])
        fetchedPolicies[checkedPolicy.index].isChecked = true;
    });
  }
  */
  return updateObject(state, {
    policies: fetchedPolicies,
    totalValuePortfolio: totalValuePortfolio,
  });
};
/**/

const updatePagination = (state, action) => {
  return updateObject(state, {
    pagination: {
      ...action.pagination,
    },
  });
};

const checkPendingPolicy = (state, action) => {
  console.log("finalState", state);
  const policy = action.policy;
  console.log("policy: ", policy);
  const updatedPendingPolicies = updatePendingPolicies(state, policy);
  return updateObject(state, updatedPendingPolicies);
};

/* ----- TODO: CALL API TO RETRIEVE ALL PENDING POLICIES ----- */

const checkAllPendingPolicies = (state, action) => {
  return updateObject(state, {
    checkedPolicies: action.checkedPolicies,
    total: action.total,
  });
};

const verifyCheckPendingPolicies = (state) => {
  const policies = state.policies;
  const checkedPolicies = state.checkedPolicies;
  if (policies && checkedPolicies) {
    const policiesEntries = Object.entries(policies);
    for (let policyEntry of policiesEntries) {
      const policyData = policyEntry[1];
      const policyKey = policyEntry[0];
      // we take the liberty id
      if (checkedPolicies[policyData._id]) {
        policies[policyData.index].isChecked = true;
        checkedPolicies[policyData._id].index = policyKey;
      }
    }
  }

  return updateObject(state, {
    policies: policies,
    checkedPolicies: checkedPolicies,
  });
};

const uncheckAllPendingPolicies = (state, action) => {
  const keys = Object.keys(state.policies);
  for (let key of keys) {
    state.policies[key].isChecked = false;
  }

  return updateObject(state, {
    checkedPolicies: {},
    policies: state.policies,
    total: 0,
  });
};

const setInsuranceCode = (state, action) => {
  return updateObject(state, {
    insuranceCode: action.insuranceCode,
  });
};

const setPreviousPendingPolicies = (state, action) => {
  let policies = state.policies;
  let prevPolicies = {};
  if (!policies) return state;
  Object.entries(policies).map((policy) => {
    prevPolicies[policy[1]["_id"]] = policy[1];
  });
  let currentPrevPolicies = state.prevPolicies;
  if (currentPrevPolicies) {
    currentPrevPolicies.push(prevPolicies);
    if (currentPrevPolicies.length > 5) {
      currentPrevPolicies.shift();
    }
  } else {
    currentPrevPolicies = [prevPolicies];
  }
  return updateObject(state, {
    prevPolicies: currentPrevPolicies,
  });
};

/* ----- TODO: CALL API TO RETRIEVE ALL PENDING POLICIES ----- */

const pending = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICIES_PENDING_FETCH:
      return fetchPendingPolicies(state, action);
    case actionTypes.POLICIES_PENDING_SET_PAGINATION:
      return updatePagination(state, action);
    case actionTypes.POLICIES_PENDING_CHECK:
      return checkPendingPolicy(state, action);
    case actionTypes.POLICIES_PENDING_CHECK_ALL:
      return checkAllPendingPolicies(state, action);
    case actionTypes.POLICIES_PENDING_UNCHECK_ALL:
      return uncheckAllPendingPolicies(state, action);
    case actionTypes.VERIFY_CHECK_PENDING_POLICIES:
      return verifyCheckPendingPolicies(state);
    case actionTypes.SET_INSURANCE_CODE:
      return setInsuranceCode(state, action);
    case actionTypes.RESET:
      return updateObject(state, initialState);
    case actionTypes.SET_PREVIOUS_PENDING_POLICIES:
      return setPreviousPendingPolicies(state, action);
    default:
      return state;
  }
};

export default pending;
