import React from "react";
import Navigator from "./navigation";
import { Provider } from "react-redux";
import axios from "axios";
import store from "./store/store";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";

axios.defaults.baseURL = "https://localhost:3000/";
axios.defaults.headers.common["Content-Type"] = "application/json";

const App = () => {
  return (
    <Provider store={store}>
      <Navigator />
    </Provider>
  );
};

export default App;
