import * as actionTypes from "../constants";
import { updateObject } from "../../utils";

const initialState = {
  paymentToken: null,
  paymentData: null,
  paymentDownloadData: null,
  paymentBillDownload: null,
  paymentBillEmail: null,
};

const setPaymentBillDownload = (state, action) => {
  return updateObject(state, {
    paymentBillDownload: action.paymentBillDownload,
  });
};

const setPaymentBillEmail = (state, action) => {
  return updateObject(state, {
    paymentBillEmail: action.paymentBillEmail,
  });
};

const setPaymentToken = (state, action) => {
  return updateObject(state, {
    paymentToken: action.paymentToken,
  });
};

const checkPaymentConfirmation = (state, action) => {
  return updateObject(state, {
    paymentData: action.paymentData,
  });
};

const downloadPaymentConfirmation = (state, action) => {
  return updateObject(state, {
    paymentDownloadData: action.paymentDownloadData,
  });
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_TOKEN:
      return setPaymentToken(state, action);
    case actionTypes.SET_PAYMENT_BILL:
      return setPaymentBillDownload(state, action);
    case actionTypes.SET_PAYMENT_BILL_EMAIL:
      return setPaymentBillEmail(state, action);
    case actionTypes.CHECK_PAYMENT_CONFIRMATION:
      return checkPaymentConfirmation(state, action);
    case actionTypes.DOWNLOAD_PAYMENT_CONFIRMATION:
      return downloadPaymentConfirmation(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default payments;
