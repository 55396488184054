const publicIp = require("public-ip");

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const getLocalIp = async () => {
  try {
    let ip = await publicIp.v4();
    console.log("ip address", ip);
    return ip;
  } catch (error) {
    console.log(error);
    return "127.0.0.1";
  }
};

export const currencyFormatter = (total) => {
  const formatter = new Intl.NumberFormat("es-EC", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  return formatter.format(total);
};

export const parsePaidPolicy = (invoice, index, isChecked = false) => {
  return {
    id: index,
    receipt: invoice.invoice_number,
    paymentDate: invoice.invoice_date,
    paymentMethod: invoice.payment_method,
    value: invoice.value,
    // paymentState,
    isChecked,
  };
};

export const parsePendingPolicy = (policy, index, isChecked = false) => {
  let parsedPolicy = {};
  if (policy.product == "payment_plan") {
    let payload = policy.policy_details.reduce((acc, bill) => {
      acc[bill.bill_number] = {
        id: parseInt(bill.bill_number, 10),
        policyNumber: parseInt(bill.policy_number, 10),
        branch: bill.branch,
        certificateNumber: 0,
        hasRetention: bill.has_retention,
        policyReceipt: parseInt(bill.bill_number, 10),
        paymentDate: bill.payday_limit,
        product: bill.product,
        total: bill.info_payment.bill_value,
        policyStatus: bill.policy_status,
        policyCancellationDays: bill.cancellation_days,
        status: bill.policy_status,
        currentFee: bill.info_payment.current_fee,
        taxPercentage: bill.info_payment.tax_percentage,
        totalFees: bill.info_payment.total_fee,
        failedDebits: bill.failed_debits || null,
        idpv: bill.idpv,
      };
      return acc;
    }, {});
    parsedPolicy = {
      index,
      _id: `${policy.plan_number}${policy.info_payment.current_fee}`,
      id: `${policy.plan_number}${policy.info_payment.current_fee}`,
      product: policy.product,
      policyNumber: policy.plan_number,
      certificateNumber: "",
      policyReceipt: "",
      branch: "Plan de pago",
      hasRetention: policy.has_retention,
      tax: policy.info_payment.tax,
      base: policy.info_payment.base,
      total: policy.info_payment.plan_value,
      paymentDate: policy.payday_limit,
      policyStatus: policy.policy_status,
      policyCancellationDays: policy.cancellation_days,
      taxPercentage: policy.info_payment.tax_percentage,
      isChecked: isChecked,
      isDeferred: policy.is_deferred, //To TEST Union: Math.random() < 0.5
      currentFee: policy.info_payment.current_fee,
      totalFees: policy.info_payment.total_fee,
      failedDebits: policy.failed_debits || null,
      payload,
      record_type: "payment_plan",
    };
  } else if (policy.record_type === "credit_notes") {
    parsedPolicy = {
      index,
      _id: `${policy.idpv}${policy.policy_number}`,
      record_type: "credit_notes",
      id: parseInt(policy.idpv, 10),
      policyNumber: parseInt(policy.policy_number, 10),
      certificateNumber: 0,
      policyReceipt: "",
      paymentDate: policy.payday_limit,
      product: policy.product,
      hasRetention: policy.has_retention,
      tax: policy.info_payment.tax,
      base: policy.info_payment.base,
      total: policy.info_payment.bill_value,
      policyStatus: policy.policy_status,
      taxPercentage: policy.info_payment.tax_percentage,
      policyCancellationDays: policy.cancellation_days,
      branch: policy.branch ? policy.branch : "",
      isChecked: isChecked,
      isDeferred: policy.is_deferred,
      currentFee: policy.info_payment.current_fee
        ? policy.info_payment.current_fee
        : 0,
      totalFees: policy.info_payment.total_fee
        ? policy.info_payment.total_fee
        : 0,
      status: policy.policy_status,
      failedDebits: policy.failed_debits || null,
      idpv: policy.idpv,
    };
  } else {
    const invoiceCurrentFee = policy.info_payment.current_fee
      ? policy.info_payment.current_fee
      : 0;
    const invoiceTotalFees = policy.info_payment.total_fee
      ? policy.info_payment.total_fee
      : 0;
    parsedPolicy = {
      index,
      _id: `${policy.bill_number}${policy.policy_number}${invoiceCurrentFee}`,
      record_type: "bill",
      id: parseInt(policy.bill_number, 10),
      policyNumber: parseInt(policy.policy_number, 10),
      certificateNumber: 0,
      policyReceipt: parseInt(policy.bill_number, 10),
      paymentDate: policy.payday_limit,
      product: policy.product,
      hasRetention: policy.has_retention,
      tax: policy.info_payment.tax,
      base: policy.info_payment.base,
      total: policy.info_payment.bill_value,
      policyStatus: policy.policy_status,
      policyCancellationDays: policy.cancellation_days,
      taxPercentage: policy.info_payment.tax_percentage,
      isChecked: isChecked,
      isDeferred: policy.is_deferred,
      status: policy.policy_status,
      currentFee: invoiceCurrentFee,
      totalFees: invoiceTotalFees,
      failedDebits: policy.failed_debits || null,
      branch: policy.branch,
      idpv: policy.idpv,
    };
  }
  // if (policy.policy_details) parsedPolicy.payload = policy.policy_details;
  // if (policy.policy_status) parsedPolicy.status = policy.policy_status;
  // if (policy.policy_status) parsedPolicy.status = policy.policy_status;
  console.log("parsed policy: ", parsedPolicy);
  return parsedPolicy;
};

export const updatePendingPolicies = (state, policy) => {
  const { _id, isChecked } = policy;
  let { policies, checkedPolicies, total, prevPolicies } = state;
  /* */
  let newPolicy = Object.values(policies).filter((tablePolicy) => {
    return tablePolicy._id === _id;
  });
  console.log(newPolicy);
  /* */
  if (newPolicy && newPolicy.length > 0) {
    newPolicy = newPolicy[0];
    if (isChecked) {
      newPolicy.isChecked = true;
      policies[newPolicy.index] = newPolicy;
      checkedPolicies = checkedPolicies ? checkedPolicies : {};
      checkedPolicies[_id] = newPolicy;
      total = total + newPolicy.total;
    } else {
      let uncheckedData = uncheckAllPreviousInstallments(
        policies,
        checkedPolicies,
        total,
        _id
      );
      checkedPolicies = uncheckedData.currentCheckedPolicies;
      policies = uncheckedData.policies;
      total = uncheckedData.total;
      // console.log('unchecked data: ', uncheckedData);
      // newPolicy.isChecked = false;
      // 119292923923 - 1
      // policies[newPolicy.index] = newPolicy;
      // uncheck policies ahead
      // total = total - newPolicy.total;
    }
  }
  if (!isChecked) {
    delete checkedPolicies[_id];
  }
  return {
    policies: { ...policies },
    checkedPolicies: { ...checkedPolicies },
    total: total,
  };
};

export const uncheckAllPreviousInstallments = (
  policies,
  checkedPolicies,
  total,
  policyId
) => {
  let currentCheckedPolicies = checkedPolicies;
  let checkedPolicy = checkedPolicies[policyId];
  let checkedPoliciesToRemove = [];
  const currentPolicyNumber = checkedPolicy.policyNumber;
  const currentPolicyFee = checkedPolicy.currentFee;
  for (const [policyId, policyPayload] of Object.entries(checkedPolicies)) {
    if (
      currentPolicyNumber == policyPayload.policyNumber &&
      currentPolicyFee <= parseInt(policyPayload.currentFee, 10)
    ) {
      checkedPoliciesToRemove.push(policyId);
      total = total - policyPayload.total;
      delete currentCheckedPolicies[policyId];
    }
  }
  console.log("policies to remove: ", checkedPoliciesToRemove);
  for (const [policyIndex, policyPayload] of Object.entries(policies)) {
    if (checkedPoliciesToRemove.includes(policyPayload._id)) {
      policyPayload.isChecked = false;
      policies[policyIndex] = policyPayload;
    }
  }

  return { currentCheckedPolicies, policies, total };
};

export const hasPreviousInstallments = (
  policies,
  prevPolicies,
  checkedPolicies,
  checkedPolicyId
) => {
  let policiesInvolved = {};
  if (prevPolicies) {
    prevPolicies.forEach((policy) => {
      policiesInvolved = { ...policiesInvolved, ...policy };
    });
  }
  policiesInvolved = { ...policiesInvolved, ...policies };
  console.log("prev policies: ", prevPolicies);
  console.log("full object: ", policiesInvolved);
  let currentPolicyData = Object.entries(policiesInvolved).filter(
    ([, policy]) => policy._id == checkedPolicyId
  );
  let previousInstallments = false;
  if (currentPolicyData && currentPolicyData[0]) {
    const currentPolicyNumber = currentPolicyData[0][1].policyNumber;
    const currentPolicyFee = currentPolicyData[0][1].currentFee;
    for (const [, policyPayload] of Object.entries(policiesInvolved)) {
      console.log("current policy: ", currentPolicyNumber);
      console.log("current policy fee: ", currentPolicyFee);
      console.log("evaluating policy: ", policyPayload);
      if (
        currentPolicyNumber == policyPayload.policyNumber &&
        currentPolicyFee > parseInt(policyPayload.currentFee, 10) &&
        currentPolicyFee == parseInt(policyPayload.currentFee, 10) + 1
      ) {
        previousInstallments = true;
        if (checkedPolicies && checkedPolicies[policyPayload._id]) {
          previousInstallments = false;
        }
        break;
      }
    }
  }
  return previousInstallments;
};

export const formatRequestReports = (
  documentNumber,
  documentType,
  userName,
  filters,
  pagination,
  search
) => {
  let requestPayload = {
    data: {
      customer: {
        document_number: documentNumber,
        document_type: documentType,
        user_name: userName,
      },
      filters: filters
        ? {
            product_type: filters.productType || "",
            order_by: filters.order?.split("-")[0] || "",
            order_type: filters.order?.split("-")[1] || "",
            start_date: filters.dateRange?.start
              ? filters.dateRange.start.toISOString()
              : "",
            end_date: filters.dateRange?.end
              ? filters.dateRange.end.toISOString()
              : "",
            field_search: search || "",
          }
        : {},
      is_all_pages: true,
      pagination: {
        page_number: pagination.activePage,
        page_size: pagination.perPage,
      },
    },
  };
  return requestPayload;
};
