/* eslint-disable no-undef */
import axios from "axios";
import { getLocalIp } from "../../utils";
import * as actionTypes from "../constants";
import { setLoading, setError } from "./user_action";

const COGNITO_AUTH_API = process.env.REACT_APP_COGNITO_AUTH_API;
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_AUTH_CLIENT_ID;
const COGNITO_CLIENT_SECRET = process.env.REACT_APP_COGNITO_AUTH_CLIENT_SECRET;
const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL;

//TODO: move to a global helper
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${access_token}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

//const result = await axiosApiInstance.post(url, data, config?)

export const login = (
  documentType,
  documentNumber = "",
  isUserCheck = false
) => {
  return (dispatch) => {
    // const userToken = sessionStorage.getItem("token");
    const requestBody = {
      data: {
        document_type: documentType,
        document_number: documentNumber.toString().trim(),
      },
    };
    // const authRequestConfig = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userToken}`,
    //   },
    // };
    const verifyUserUrl = `${BACKEND_API}/users/client/login`;
    dispatch(setLoading(true));
    dispatch(setLoginStart());
    axiosApiInstance
      .post(verifyUserUrl, requestBody) //, authRequestConfig)
      .then(async (response) => {
        const userInformation = response.data.data;
        console.log("user information: ", userInformation);
        console.log("log user information", userInformation);
        dispatch(authSuccess(userInformation));
        if (!isUserCheck) {
          let ip = await getLocalIp();
          console.log("local ip", ip);
          sessionStorage.setItem("ip", ip);
          sessionStorage.setItem(
            "profile_info",
            JSON.stringify(userInformation)
          );
          sessionStorage.setItem(
            "document_number",
            userInformation.document_number
          );
          sessionStorage.setItem(
            "document_type",
            userInformation.document_type
          );
        }
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        dispatch(setLoginError());
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    sessionStorage.removeItem("document_type");
    sessionStorage.removeItem("profile_info");
    sessionStorage.removeItem("document_number");
    dispatch(clearUserSession());
    dispatch(reset());
  };
};

export const setLoginError = () => {
  return {
    type: actionTypes.AUTH_FAIL,
  };
};

export const setLoginStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const portalAuthenticated = (token) => {
  return {
    type: actionTypes.PORTAL_AUTHENTICATED,
    token: token,
  };
};

const refreshAccessToken = () => {
  return new Promise((resolve, reject) => {
    let authString = `${COGNITO_CLIENT_ID}:${COGNITO_CLIENT_SECRET}`;
    console.log("auth string", authString);
    let buffer = new Buffer(authString);
    let base64Auth = buffer.toString("base64");
    const authRequestConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${base64Auth}`,
      },
    };
    const requestBody = "grant_type=client_credentials";
    axios
      .post(COGNITO_AUTH_API, requestBody, authRequestConfig)
      .then((response) => {
        const { access_token } = response.data;
        sessionStorage.setItem("token", access_token);
        resolve(access_token);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const requestAccessPortal = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    refreshAccessToken()
      .then((token) => {
        dispatch(portalAuthenticated(token));
      })
      .catch((error) => {
        console.log("error requesting token: ", error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    let documentNumber = sessionStorage.getItem("document_number");
    let documentType = sessionStorage.getItem("document_type");
    let userInfo = JSON.parse(sessionStorage.getItem("profile_info"));
    if (documentNumber && documentType) {
      dispatch(authSuccess(userInfo));
    } else {
      logout();
    }
  };
};

export const setEmail = (email) => {
  return {
    type: actionTypes.ACTION_SET_EMAIL,
    email: email,
  };
};

const authSuccess = (userProfile) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    userProfile: userProfile,
  };
};

const clearUserSession = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

const reset = () => {
  return {
    type: actionTypes.RESET,
  };
};
