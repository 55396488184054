import React from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import propTypes from "prop-types";

const CustomModal = ({
  show = false,
  onHide = () => {},
  size = "sm",
  title = "",
  subtitle = "",
  text = "",
  question = "",
  button = "",
  cancelButton = null,
  action = null,
  actionCancel = null,
  icon = null,
}) => {
  return (
    <Modal show={show} onHide={onHide} size={size} centered>
      <Modal.Body>
        <Row>
          <Col className="text-center px-0">
            <Row>
              <Col>
                {icon && (
                  <Row>
                    <Col>
                      <img alt="" src={icon} height="60" />
                    </Col>
                  </Row>
                )}
                {title ? (
                  <Row className="mt-3">
                    <Col>
                      <h5 className="title font-weight-normal">{title}</h5>
                    </Col>
                  </Row>
                ) : null}
                <Row className="mt-3">
                  <Col>
                    <h4 className="title modal-title font-roboto-bold">
                      {subtitle}
                    </h4>
                  </Col>
                </Row>
                {text ? (
                  <Row className="mt-3">
                    <Col>
                      <p className="m-0 font-size-sm font-weight-normal">
                        {text}
                      </p>
                    </Col>
                  </Row>
                ) : null}
                {question ? (
                  <Row className="mt-2">
                    <Col>
                      <p className="m-0 font-size-sm font-weight-normal">
                        {question}
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <br />
            <Row className="justify-content-center mt-2">
              <Col lg="10" className="my-2">
                {cancelButton && (
                  <Button
                    className="btn-liberty btn-liberty__cancel font-weight-bold btn-block font-roboto-bold"
                    onClick={() => {
                      if (actionCancel) {
                        actionCancel();
                      } else {
                        onHide();
                      }
                    }}
                  >
                    {cancelButton}
                  </Button>
                )}
                <Button
                  className="btn-liberty btn-liberty__success btn-primary font-weight-bold btn-block font-roboto-bold"
                  onClick={() => {
                    if (action) {
                      action();
                    } else {
                      onHide();
                    }
                  }}
                >
                  {button}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

CustomModal.propTypes = {
  show: propTypes.bool,
  onHide: propTypes.func,
  size: propTypes.string,
  title: propTypes.string,
  subtitle: propTypes.string,
  question: propTypes.string,
  text: propTypes.string,
  button: propTypes.string,
  action: propTypes.func,
  icon: propTypes.any,
  cancelButton: propTypes.string,
  actionCancel: propTypes.func,
};

export default CustomModal;
