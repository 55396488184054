import { combineReducers } from "redux";
import auth from "./auth";
import userAction from "./user_action";
import genericParams from "./generic_params";
import paidPolicies from "./paid_policies";
import pendingPolicies from "./pending_policies";
import payments from "./payments";

const appReducers = combineReducers({
  auth,
  userAction,
  genericParams,
  paidPolicies,
  pendingPolicies,
  payments,
});

const rootReducer = (state, action) => {
  let reducers = appReducers(state, action);
  return reducers;
};

export default rootReducer;
