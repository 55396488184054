export const PORTAL_AUTHENTICATED = "PORTAL_AUTHENTICATED";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_START = "AUTH_START";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const ACTION_SET_IS_LOADING = "ACTION_SET_IS_LOADING";
export const ACTION_SET_ERROR = "ACTION_SET_ERROR";
export const ACTION_SET_EMAIL = "ACTION_SET_EMAIL";
export const ACTION_UNSET_ERROR = "ACTION_UNSET_ERROR";
export const FETCH_IDENTIFICATION_TYPES = "FETCH_IDENTIFICATION_TYPES";
export const FETCH_PAYMENT_LIMITS = "FETCH_PAYMENT_LIMITS";
export const POLICIES_PAID_FETCH = "POLICIES_PAID_FETCH";
export const POLICIES_PAID_CHECK_ALL = "POLICIES_PAID_CHECK_ALL";
export const POLICIES_PAID_DOWNLOAD = "POLICIES_PAID_DOWNLOAD";
export const POLICIES_PAID_SET_PAGINATION = "POLICIES_PAID_SET_PAGINATION";
export const POLICIES_PENDING_SET_PAGINATION =
  "POLICIES_PENDING_SET_PAGINATION";
export const POLICIES_PENDING_FETCH = "POLICIES_PENDING_FETCH";
export const POLICIES_PENDING_CHECK = "POLICIES_PENDING_CHECK";
export const POLICIES_PENDING_CHECK_ALL = "POLICIES_PENDING_CHECK_ALL";
export const POLICIES_PENDING_UNCHECK_ALL = "POLICIES_PENDING_UNCHECK_ALL";
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN";
export const SET_PAYMENT_BILL = "SET_PAYMENT_BILL";
export const SET_PAYMENT_BILL_EMAIL = "SET_PAYMENT_BILL_EMAIL";
export const CHECK_PAYMENT_CONFIRMATION = "CHECK_PAYMENT_CONFIRMATION";
export const DOWNLOAD_PAYMENT_CONFIRMATION = "DOWNLOAD_PAYMENT_CONFIRMATION";
export const RESET = "RESET";
export const VERIFY_CHECK_PENDING_POLICIES = "VERIFY_CHECK_PENDING_POLICIES";
export const SET_INSURANCE_CODE = "SET_INSURANCE_CODE";
export const SET_PREVIOUS_PENDING_POLICIES = "SET_PREVIOUS_PENDING_POLICIES";
