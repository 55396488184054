/* eslint-disable no-undef */
import * as actionTypes from "../constants";
import axios from "axios";
import { parsePaidPolicy } from "../../utils";
import { setLoading, setError } from "./user_action";

const DATA_API = process.env.REACT_APP_BACKEND_API_URL;

const setPolicies = (policies) => {
  return {
    type: actionTypes.POLICIES_PAID_FETCH,
    policies,
  };
};

const setPagination = (pagination) => {
  return {
    type: actionTypes.POLICIES_PAID_SET_PAGINATION,
    pagination,
  };
};

const setInvoiceDownload = (invoicesDownloadData) => {
  return {
    type: actionTypes.POLICIES_PAID_DOWNLOAD,
    invoicesDownloadData,
  };
};

//TODO: Test Download once back is ready. This should be on API or services folder, as it does not load info in the store
export const downloadPaidSummary = (selectedPolicies = []) => {
  return (dispatch) => {
    let requestPayload = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
        },
        invoices: selectedPolicies.map((invoice) =>
          parseInt(invoice.receipt, 10)
        ),
      },
    };

    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    console.log(requestPayload);
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(`${DATA_API}/policy/paid/invoices`, requestPayload, requestConfig)
      .then((response) => {
        if (response) {
          let invoicesDownloadData = null;

          invoicesDownloadData = {
            filename: response.data.file_name,
            temporaryUrl: response.data.temporary_url,
          };
          dispatch(setInvoiceDownload(invoicesDownloadData));

          window.open(invoicesDownloadData.temporaryUrl);
        } else {
          dispatch(setInvoiceDownload(null));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setInvoiceDownload(null));
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const fetchPaidPolicies = (
  pagination = {
    activePage: 1,
    perPage: 5,
  },
  filters = {
    productType: "",
    order: "FECHA-ASC",
    dateRange: { start: "", end: "" },
  },
  search,
  checkAll = false
) => {
  return (dispatch) => {
    console.log("parameter fetch paid policies: ", pagination);
    let requestPayload = {
      data: {
        customer: {
          document_number: sessionStorage.getItem("document_number"),
          document_type: sessionStorage.getItem("document_type"),
        },
        pagination: {
          page_number: pagination.activePage,
          page_size: pagination.perPage,
        },
        is_all_pages: checkAll ? true : false,
        filters: {
          payment_status: filters.paymentStatus || "",
          order_by: filters.order.split("-")[0] || "",
          order_type: filters.order.split("-")[1] || "",
          start_date: filters.dateRange?.start
            ? filters.dateRange.start.toISOString().replace("Z", "")
            : "",
          end_date: filters.dateRange?.end
            ? filters.dateRange.end.toISOString().replace("Z", "")
            : "",
          field_search: search || "",
        },
      },
    };

    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    console.log(requestPayload);
    dispatch(setLoading(true));
    dispatch(setError(false));
    axios
      .post(`${DATA_API}/policy/paid/index`, requestPayload, requestConfig)
      .then((response) => {
        if (response) {
          let invoices = [];
          console.log("invoices: ", response.data.invoices);
          invoices = response.data.invoices.map((invoice, index) =>
            parsePaidPolicy(invoice, index, checkAll)
          );
          console.log(`NUMBER OF FETCHED POLICIES: ${invoices.length}`);

          if (!checkAll) {
            console.log("response for object invoices: ", invoices);
            dispatch(setPolicies(invoices));
            dispatch(
              setPagination({
                ...pagination,
                totalItems: response.data.total_invoices,
              })
            );
            // with all pending policies dispatch select policy
          } else {
            //dispatch(setPolicies(invoices));
            dispatch(checkAllPaidPolicies(invoices));
          }
        } else {
          dispatch(setPolicies([]));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setPolicies([]));
        if (error.response && error.response.status != 400)
          dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const checkAllPaidPolicies = (checkedPolicies) => {
  return {
    type: actionTypes.POLICIES_PAID_CHECK_ALL,
    checkedPolicies,
  };
};
