import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import * as actions from "../store/actions";
import Loading from "../components/templates/misc/loading/Loading";
import { getLocalIp } from "../utils";
import IdleTimer from "react-idle-timer";
import CustomModal from "../components/collections/modals/CustomModal";

import alertModalIcon from "../assets/images/alert-error.svg";

const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));
const AuthLayout = React.lazy(() => import("./layouts/AuthLayout"));

const Navigator = () => {
  const loadingTemplate = () => (
    <div className="animated fadeIn pt-1 text-center">
      <Loading />
    </div>
  );

  const [isInactive, setIsInactive] = useState(false);

  const { userProfile, logout } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.userAction);
  const dispatch = useDispatch();

  useEffect(() => {
    async function authFetch() {
      try {
        let ip = await getLocalIp();
        console.log("local ip", ip);
        sessionStorage.setItem("ip", ip);
        dispatch(actions.authCheckState());
        dispatch(actions.requestAccessPortal());
      } catch (error) {
        console.log(error);
        sessionStorage.setItem("ip", "127.0.0.1");
      }
    }
    authFetch();
  }, []);

  const logoutAction = () => {
    dispatch(actions.logout());
    if (window.location.href.includes("payment/confirmation")) {
      window.location.href = "/";
    }
    // dispatch(actions.requestAccessPortal());
  };

  const handleOnIdle = () => {
    logoutAction();
    setIsInactive(true);
  };

  const handleClose = () => {
    setIsInactive(false);
  };

  const nonAuthroutes = (
    <Switch>
      <Route
        path="/"
        name="Home"
        render={(props) => <DefaultLayout {...props} loading={loading} />}
      />
    </Switch>
  );

  const authRoutes = (
    <Switch>
      <Route
        path="/"
        name="Home"
        render={(props) => (
          <AuthLayout
            {...props}
            loading={loading}
            logoutAction={logoutAction}
          />
        )}
      />
    </Switch>
  );

  return (
    <React.Suspense fallback={loadingTemplate()}>
      {isInactive ? (
        <CustomModal
          show={isInactive}
          size={"sm"}
          onHide={handleClose}
          icon={alertModalIcon}
          subtitle={"Tu sesión ha expirado"}
          text={
            "Haz superado el tiempo de inactividad, por seguridad hemos cerrado la sesión."
          }
          button={"Cerrar"}
          action={handleClose}
        />
      ) : null}
      {userProfile &&
        !logout &&
        !window.location.href.includes("payment/confirmation") && (
          <IdleTimer
            timeout={1000 * 60 * 5}
            onIdle={handleOnIdle}
            startOnMount
            debounce={250}
          />
        )}
      <BrowserRouter>
        {userProfile && !logout ? authRoutes : nonAuthroutes}
        {logout && !window.location.href.includes("payment/confirmation") ? (
          <Redirect to="/" />
        ) : null}
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Navigator;
