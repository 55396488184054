import * as actionTypes from "../constants";
import { updateObject } from "../../utils";

const initialState = {
  loading: false,
  error: false,
};

const action = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_SET_IS_LOADING:
      return setIsLoading(state, action);
    case actionTypes.ACTION_SET_ERROR:
      return setError(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default action;

const setIsLoading = (state, action) => {
  if (state.loading && action.loading) return;
  return updateObject(state, {
    loading: !state.loading,
  });
};

const setError = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};
