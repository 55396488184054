import * as actionTypes from "../constants";
import { updateObject } from "../../utils";

const initialState = {
  email: null,
  logout: true,
  token: null,
  userProfile: null,
  error: false,
};

const authPortal = (state, action) => {
  return updateObject(state, {
    token: action.token,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    userProfile: action.userProfile,
    logout: false,
    error: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: true,
    logout: true,
  });
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: false,
    logout: true,
  });
};

const setLogout = (state, action) => {
  return updateObject(state, {
    logout: true,
    error: false,
    userProfile: null,
  });
};

const setEmail = (state, action) => {
  return updateObject(state, {
    email: action.email,
  });
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_LOGOUT:
      return setLogout(state, action);
    case actionTypes.ACTION_SET_EMAIL:
      return setEmail(state, action);
    case actionTypes.PORTAL_AUTHENTICATED:
      return authPortal(state, action);
    // NO RESET on AUTH as LOGOUT action and flag supports redirects.
    // case actionTypes.RESET:
    //   return initialState;
    default:
      return state;
  }
};

export default auth;
