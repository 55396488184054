import * as actionTypes from "../constants";
import axios from "axios";
// consts
const BACKEND_API = process.env.REACT_APP_BACKEND_API_URL;

export const fetchUserEmail = (userId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    const usersDataUrl = `${BACKEND_API}/users/client/show/email/${userId}`;
    axios
      .get(usersDataUrl, requestConfig)
      .then((data) => {
        if (data.data.email) {
          dispatch(setUserEmail(data.data.email));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status != 404) {
          dispatch(setError(true));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const registerUserEmail = (documentNumber, documentType, email) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    const requestBody = {
      data: {
        document_number: documentNumber,
        document_type: documentType,
        email: email,
      },
    };
    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    const usersDataUrl = `${BACKEND_API}/users/client/create/email`;
    axios
      .post(usersDataUrl, requestBody, requestConfig)
      .then((response) => {
        console.log("REGISTERED EMAIL: ", response);
        dispatch(setUserEmail(response.data.data.email));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setError = (error) => {
  return {
    type: actionTypes.ACTION_SET_ERROR,
    error: error,
  };
};

export const setLoading = (isLoading) => {
  return {
    type: actionTypes.ACTION_SET_IS_LOADING,
    isLoading,
  };
};

const setUserEmail = (email) => {
  return {
    type: actionTypes.ACTION_SET_EMAIL,
    email: email,
  };
};
