import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

window.dataLayer = window.dataLayer || [];

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
